import React, { useState, useEffect, useMemo } from "react"

import { FormatCurrency } from "../../../utils/utils"
import { MONTH_YEAR_ROLLOVER, GetYearFilterOptions, GetDefaultSelectedYear, FilterRecords } from "../../../../utils/reports"

// @ts-ignore
import * as styles from "../TableStyles.module.css"

function RegistrationReportTable():JSX.Element {
  const [records,setRecords] = useState<Array<OnlineRegistrationRecord|OnsiteRegistrationRecord>>([]),
        [isLoading,setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        [yearOptions,setYearOptions] = useState<Array<number>>([]),
        [filteredYear,setFilteredYear] = useState<number>()

  function loadRecords () {
    setIsLoading(true)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports?t=registrations`, {
      method: "GET"
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        let newRecords = response.data.map((ref:any)=>{
          return {
            ...ref.data,
            last_modified: new Date(ref.ts/1000)
          }
        }).filter((a:any) => { return a ? true : false})
          .filter((a:any) => { return a.payment_status && a.payment_status === "paid" ? true : false})
        setRecords(newRecords)
        // Calculate possible year filters
        setFilteredYear(GetDefaultSelectedYear())
        setYearOptions(GetYearFilterOptions())
        setIsLoading(false)
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to load records")
      })
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorMessage("Failed to load records")
    })
  }

  useEffect(()=>{
    loadRecords()
  }, [])

  function copyData() {
    let data = records.map((record) => {
      if(record.form_type === "online") {
        let onlineData = record as OnlineRegistrationRecord
        return [
          onlineData.last_modified?.toLocaleString() || "",
          onlineData.form_type,
          onlineData.registrationType,
          FormatCurrency(onlineData.amount_charged || 0),
          onlineData.firstName,
          onlineData.lastName,
          onlineData.affiliation,
          onlineData.address1,
          onlineData.address2,
          onlineData.city,
          onlineData.state,
          onlineData.zip,
          onlineData.country,
          onlineData.telephone,
          onlineData.email
        ].join('\t')
      } else {
        let onsiteData = record as OnsiteRegistrationRecord
        return [
          onsiteData.last_modified?.toLocaleString() || "",
          onsiteData.form_type,
          onsiteData.registrationType,
          FormatCurrency(onsiteData.amount_charged || 0),
          onsiteData.firstName,
          onsiteData.lastName,
          onsiteData.affiliation,
          onsiteData.address1,
          onsiteData.address2,
          onsiteData.city,
          onsiteData.state,
          onsiteData.zip,
          onsiteData.country,
          onsiteData.telephone,
          onsiteData.email,
          onsiteData.willAttendWelcomeReception ? "Yes" : "No",
          onsiteData.additionalWelcomeReceptionGuestsCount,
          onsiteData.willAttendBanquet ? "Yes" : "No",
          onsiteData.additionalBanquetAdultMeals,
          onsiteData.additionalBanquetChildMeals
        ].join('\t')
      }
    }).join('\n')
    navigator.clipboard.writeText(data)
  }

  function onUpdateFilter(e:React.ChangeEvent<HTMLSelectElement> ) {
    try {
      let newYear :number|undefined
      newYear = parseInt(e.target.value)
      if(newYear && newYear > 2020) {
        setFilteredYear(newYear)
        return
      }
    } catch(err) {
    }
    setFilteredYear(undefined)
  }

  const visibleResults:Array<FormRecord> = useMemo(()=>{
    return FilterRecords(records,filteredYear)
  },[yearOptions,filteredYear,records])

  return (
    <div>
      <div className={styles.infoBar}>
        <div>
          <button onClick={copyData}>Copy</button>
          <p style={{paddingLeft: 10}}>{`${visibleResults.length} Records`}</p>
        </div>
        {isLoading ? (<p>Loading...</p>) : null}
        <div>
          <span className={styles.field}>
            <label title={`Years rollover on the ${MONTH_YEAR_ROLLOVER+1} month `}>Year</label>
            <select value={filteredYear} onChange={onUpdateFilter}>
              <option value="">All</option>
              {yearOptions.map((yr)=>(
                <option key={yr} value={yr}>{`${MONTH_YEAR_ROLLOVER+1}/${yr-1} through ${MONTH_YEAR_ROLLOVER}/${yr}`}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
      {errorMessage ? (<p>{errorMessage}</p> ) : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Form</th>
              <th>Type</th>
              <th>Amount Charged</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Affiliation</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Telephone</th>
              <th>Email</th>
              <th>Attend Reception</th>
              <th>Reception Additional Guests</th>
              <th>Attend Banquet</th>
              <th>Extra Adult Meals</th>
              <th>Extra Child Meals</th>
            </tr>
          </thead>
          <tbody>
            {visibleResults.map((record)=>{
              if(record.form_type === "online") {
                let onlineData = record as OnlineRegistrationRecord
                return (
                  <tr key={onlineData.client_reference_id}>
                    <td>{onlineData.last_modified?.toLocaleString()}</td>
                    <td>{onlineData.form_type}</td>
                    <td>{onlineData.registrationType}</td>
                    <td>{FormatCurrency(onlineData.amount_charged || 0)}</td>
                    <td>{onlineData.firstName}</td>
                    <td>{onlineData.lastName}</td>
                    <td>{onlineData.affiliation}</td>
                    <td>{onlineData.address1}</td>
                    <td>{onlineData.address2}</td>
                    <td>{onlineData.city}</td>
                    <td>{onlineData.state}</td>
                    <td>{onlineData.zip}</td>
                    <td>{onlineData.country}</td>
                    <td>{onlineData.telephone}</td>
                    <td>{onlineData.email}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )
              } else {
                let onsiteData = record as OnsiteRegistrationRecord
                return (
                  <tr key={onsiteData.client_reference_id}>
                    <td>{onsiteData.last_modified?.toLocaleString()}</td>
                    <td>{onsiteData.form_type}</td>
                    <td>{onsiteData.registrationType}</td>
                    <td>{FormatCurrency(onsiteData.amount_charged || 0)}</td>
                    <td>{onsiteData.firstName}</td>
                    <td>{onsiteData.lastName}</td>
                    <td>{onsiteData.affiliation}</td>
                    <td>{onsiteData.address1}</td>
                    <td>{onsiteData.address2}</td>
                    <td>{onsiteData.city}</td>
                    <td>{onsiteData.state}</td>
                    <td>{onsiteData.zip}</td>
                    <td>{onsiteData.country}</td>
                    <td>{onsiteData.telephone}</td>
                    <td>{onsiteData.email}</td>
                    <td>{onsiteData.willAttendWelcomeReception ? "Yes" : "No"}</td>
                    <td>{onsiteData.additionalWelcomeReceptionGuestsCount}</td>
                    <td>{onsiteData.willAttendBanquet ? "Yes" : "No"}</td>
                    <td>{onsiteData.additionalBanquetAdultMeals}</td>
                    <td>{onsiteData.additionalBanquetChildMeals}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RegistrationReportTable